import { mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  async created() {},
  computed: {
    // map getters for user store:
    ...mapGetters("user", ["getUserName", "getRoles", "getEmail", "getUser"]),
  },
  methods: {
    isValidUser() {
      let valid = false;
      if (this.getRoles.length > 0) {
        valid = true;
      }
      return valid;
    },
    checkUserPlan(plan) {
      if (!this.isValidUser()) return false;
      let hasPlan = false;
      if (this.getRoles.includes(plan)) {
        hasPlan = true;
      }
      return hasPlan;
    },
    hasEssesentialPlan() {
      return this.checkUserPlan("Essential Plan");
    },
    hasExpertPlan() {
      return this.checkUserPlan("Expert Plan");
    },
    hasPremiumPlan() {
      return this.checkUserPlan("Professional Plan");
    },
    IsDeveloper() {
      return this.checkUserPlan("Developer");
    },
  },
};
