import Repository from "./Repository";

const resource = "smart-note-templates";

export default {
  async get() {
    const response = await Repository.get(`${resource}?PageSize=100`);
    return response;
  },

  async getById(id) {
    const response = await Repository.get(`${resource}/${id}`);
    return response;
  },

  async getByName(name) {
    const response = await Repository.get(`${resource}?name=${name}`);
    console.log("response: ", response);
    return response;
  },

  // returns a list of smart note templates that have already a group in the given project
  async getFavorites(projectId) {
    const response = await Repository.get(`${resource}/favorites/${projectId}`);
    return response;
  },

  async post(formData) {
    const response = await Repository.post(`${resource}`, formData);
    return response;
  },

  async postAsync(formData) {
    const response = await Repository.post(`${resource}`, formData);
    return response.data;
  },

  async putAsync(formData) {
    const response = await Repository.put(`${resource}`, formData);
    return response.data;
  },

  async delete(id) {
    const response = await Repository.delete(`${resource}/${id}`);
    return response;
  },

  async export(id) {
    return await Repository.get(`${resource}/${id}/export`, { responseType: "blob" });
  },
};
