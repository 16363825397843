import DocumentApi from "@/Repository/Document.api";

/*
    general.js

    Holds general information about the whole project.

 */
const getDefaultState = () => {
  return {
    userName: "",
    roles: "",
    email: "",
    cloudResourceAccessInfo: {},
  };
};

const state = getDefaultState;

const getters = {
  getUserName: (state) => state.userName,
  getRoles: (state) => state.roles,
  getEmail: (state) => state.email,
  getCloudResourceAccessInfo: (state) => state.cloudResourceAccessInfo,
  getUser: (state) => {
    return {
      name: state.userName,
      roles: state.roles,
      email: state.email,
    };
  },
  getShortName: (state) => getShortNameFromEmail(state),
};

const mutations = {
  setUser(state, value) {
    state.userName = value.name;
    state.roles = value.roles;
    state.email = value.email;
  },
  setCloudResourceAccessInfo(state, value) {
    state.cloudResourceAccessInfo = value;
  },
};

const actions = {
  setUser: function (context, value) {
    context.commit("setUser", value);
  },
  resetUser: function (context) {
    context.commit("setUser", getDefaultState());
  },
  // refresh the cloud resource access info
  refreshCloudResourceAccessInfo: async function (context) {
    let response = await DocumentApi.getSignedUrl();
    context.commit("setCloudResourceAccessInfo", response.data);
  },
  /*   loadCompany: async function (context) {
    const user = await UserRepository.getActive();
    context.commit("setUser", user.data);

    const result = await CompanyRepository.getCompanyByEmail(user.data.contact.communication.email);

    context.commit("setCompany", result.data);
  }, */
};

function getShortNameFromEmail(state) {
  const email = state.email;
  try {
    // Extract the name part from the email
    let namePart = email.substring(0, email.lastIndexOf("@"));

    // Split the name part by common delimiters
    // eslint-disable-next-line no-useless-escape
    let parts = namePart.split(/[\.\-_]/);

    // Get the first letter of each part
    let initials = parts.map((part) => part.charAt(0).toUpperCase());

    // Combine all initials into one string
    let shortName = initials.join("");
    return shortName;
  } catch (e) {
    console.error("[user.js] Error getting user short name from email: " + e);
    return "";
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
