import GroupApi from "@/Repository/Group.api";
import Vue from "vue";
import _ from "lodash";

const getDefaultState = () => ({
  groups: [],
});

const state = getDefaultState;

const getters = {};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  addGroup(state, group) {
    state.groups.push(group);
  },
  updateGroup(state, group) {
    const index = state.groups.findIndex((storeGroup) => storeGroup.id === group.id);

    Vue.set(state.groups, index, group);
  },
  deleteGroup(state, group) {
    const index = state.groups.findIndex((storeGroup) => storeGroup.id === group.id);

    state.groups.splice(index, 1);
  },
};

const actions = {
  async addGroup(context, { group, children }) {
    let newGroup = _.clone(group);

    //find the child construction ids from blueprintIds
    newGroup.constructions = context.rootState.construction["constructions"]
      .filter((cons) => children.some((child) => child === cons.blueprintId))
      .map((cons) => ({
        id: cons.id,
      }));

    newGroup.projectId = context.rootState.project["activeProject"].id;

    const result = await GroupApi.post(newGroup);
    if (result.status !== 200 && result.status !== 201) {
      console.error("Could not store new group.");
      return;
    }

    for (let construction of newGroup.constructions) {
      let updateConstruction = _.cloneDeep(
        context.rootState.construction["constructions"].find((cons) => cons.id === construction.id)
      );
      if (updateConstruction == null) {
        console.error("Invalid construction saved.");
        return;
      }

      updateConstruction.groupId = result.data.id;
      context.commit("construction/updateConstruction", updateConstruction, { root: true });
    }

    context.commit("addGroup", result.data);
  },
  async updateGroup(context, { group, children }) {
    let updateGroup = _.clone(group);

    const storeGroup = context.state["groups"].find((gr) => gr.blueprintId === group.blueprintId);
    if (storeGroup == null) {
      console.error("Could not find store group");
      return;
    }

    updateGroup.id = storeGroup.id;

    updateGroup.constructions = context.rootState.construction["constructions"]
      .filter((cons) => children.some((child) => child === cons.blueprintId))
      .map((cons) => ({
        id: cons.id,
      }));

    const result = await GroupApi.put(updateGroup);
    if (result.status !== 200 && result.status !== 201) {
      console.error("Could not store update group.");
      return;
    }

    context.commit("updateGroup", result.data);
  },
  async deleteGroup(context, group) {
    const storeGroup = context.state["groups"].find((gr) => gr.id === group.id);
    if (storeGroup == null) {
      console.error("Could not find store group");
      return;
    }

    await GroupApi.delete(group.id);

    context.commit("deleteGroup", group);
  },
  async loadGroupsFromDb(context) {
    const result = await GroupApi.get(context.rootState.project["activeProject"].id);
    if (result.status !== 200 && result.status !== 201) {
      console.error("Could not load groups");
      return;
    }

    for (let group of result.data) {
      context.commit("addGroup", group);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
