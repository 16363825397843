/*
DrawType.api.js
Created for SmartbricksConfigurators
by Tristan Martin and the amazing
template function of WebStorm.

*/
import Repository from "./Repository";

const resource = "draw-types";
export default {
  getBlueprint: () => Repository.get(`${resource}/blueprint`),
};
