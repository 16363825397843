<template>
  <div v-if="IsDeveloper()">
    <v-btn-toggle v-if="showMenu" :value="baseUrl" tile color="primary" group mandatory>
      <v-btn v-for="(baseUrl, index) in baseUrlModes" :key="index" @click="changeUrl(baseUrl.value)">
        {{ baseUrl.value }}
      </v-btn>
    </v-btn-toggle>
    <v-btn icon @click="$emit('show', !showMenu)">
      <v-icon>mdi-eye-off-outline</v-icon>
    </v-btn>
  </div>
</template>

<script>
import checkUserRights from "@/mixins/checkUserRights";
import AppModeMixin from "@/mixins/appModeMixin";

export default {
  name: "BaseUrlSelector",
  mixins: [checkUserRights, AppModeMixin],
  model: {
    prop: "showMenu",
    event: "show",
  },
  props: {
    showMenu: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped></style>
