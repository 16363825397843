import helpersMixin from "./helpersMixin";
import auth0 from "@/auth/auth0";

export default {
  mixins: [helpersMixin],
  data() {
    return {
      devSignedIn: false,
    };
  },
  async created() {
    console.log("[", this.$options.name, "] try to geht user info from auth0");
    try {
      await auth0.getTokenSilently();
      let user = await auth0.getUser();
      console.log("[", this.$options.name, "] user info: ", user);
      // save user to store
      this.$store.dispatch("user/setUser", {
        name: user.name,
        roles: user["https://auth.smartbricks.at/roles"],
        email: user.email,
      });
      console.log("user in store: ", this.$store.getters["user/getUser"]);
      await this.$store.dispatch("user/refreshCloudResourceAccessInfo");

      // save user to store
      if (user.email.includes("@smartbricks.at")) {
        this.devSignedIn = true;
      }
    } catch {
      this.devSignedIn = false;
    }
  },
  async getCurrUserEmail() {
    let mail = "";
    try {
      let user = await auth0.getUser();
      mail = user.email;
    } catch (e) {
      console.error("[authInfo mixin] unable to get user email bc of: ", e);
    }
    return mail;
  },
  methods: {
    logInWithRedirTo() {
      let redir = window.location.protocol + "//" + location.host + "/";
      auth0.loginWithRedirect({ redirect_uri: redir });
    },
    async logout() {
      let redir = window.location.protocol + "//" + location.host + "/logedOut";
      console.log("redirect after logout to: ", redir);
      // reset user in store
      this.$store.dispatch("user/resetUser");
      console.log("user in store: ", this.$store.getters["user/getUser"]);
      await auth0.logout({ returnTo: redir });
      // this.$router.push("loggedOut");
    },
  },
};
