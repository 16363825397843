<template>
  <v-navigation-drawer
    v-if="!isMobileView"
    :key="$route.fullPath"
    v-model="drawer"
    app
    permanent
    expand-on-hover
    :mini-variant.sync="mini"
    mini-variant-width="75"
    style="transform: translateX(0) !important; visibility: visible !important"
  >
    <v-list nav>
      <v-list-item-group color="primary">
        <!-- with color white the logo will get no shaddow when on home -->
        <v-list-item v-if="linkBackToMain" color="white" nuxt to="/">
          <v-list-item-icon>
            <icon-base width="25px" class="ml-1" height="25px" icon-name="logo" icon-color="black" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title style="color: #000000">SMARTBRICKS</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else nuxt disabled>
          <icon-base width="25px" class="ml-1" height="25px" icon-name="logo" icon-color="black" />
        </v-list-item>
        <v-list-item
          v-for="(page, index) in pages"
          v-show="!page.visible"
          :key="index"
          :disabled="page.disabled"
          nuxt
          link
          :to="page.link"
          @click="closeNav"
        >
          <v-list-item-icon>
            <v-icon large>
              {{ page.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ page.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="showSmartcademyLink" href="https://www.smartbricks.at/smartcademy" target="_blank">
          <v-list-item-icon>
            <v-icon large>mdi-school-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Smartcademy</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
      <!-- <v-list-item class="px-2">
        <v-btn icon @click.stop="mini = !mini">
          <v-icon>{{ mini ? "mdi-chevron-right" : "mdi-chevron-left" }}</v-icon>
        </v-btn>
      </v-list-item> -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import iconBase from "@/components/core/Icons/iconBase";
export default {
  name: "MainSidebar",
  components: {
    iconBase,
  },
  props: {
    pages: {
      type: Array,
      required: true,
    },
    linkBackToMain: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isMobileView: false,
      mini: true,
      drawer: true,
    };
  },
  computed: {
    showSmartcademyLink() {
      return this.$route.path !== "/project" && this.$route.path !== "/quantitySurveyProject";
    },
  },
  watch: {
    $route: {
      deep: true,
      handler(newVal, oldVal) {
        this.drawer = false;
      },
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      this.isMobileView = window.innerWidth < 450;
    },
    closeNav() {
      if (!this.mini) {
        this.mini = true;
      }
    },
  },
};
</script>

<style scoped>
.nuxtLogoActive {
  color: (0, 0, 0, 0.1);
  border-radius: 50%;
  padding: 5px;
}
</style>
