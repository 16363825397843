export default {
  data: () => ({}),
  async created() {},
  computed: {
    isTouchDevice() {
      try {
        return window.matchMedia("(pointer: coarse)").matches;
      } catch (e) {
        console.log(e);
        return false;
      }
    },
  },
  methods: {
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    round(num, decimals = 0) {
      num = Math.round(Number(num + "e" + decimals));
      return Number(num + "e" + -decimals);
    },
    recursiveScrollToTop(element) {
      if (element) {
        element.scrollTop = 0;
        element.scrollLeft = 0;
        this.recursiveScrollToTop(element.parentNode);
      }
    },

    // duplicate!
    preventIOSZoom(event) {
      console.trace("legacy preventIOSZoom");
      if (!this.tapedTwice) {
        this.tapedTwice = true;
        setTimeout(function () {
          this.tapedTwice = false;
        }, 300);
        return false;
      }
      event.preventDefault();
      //action on double tap goes below
      console.log("tapped twice!");
      document.body.style.zoom = 1;
      document.body.style.transform = "scale(" + 1 + ")";
      document.body.style["-o-transform"] = "scale(" + 1 + ")";
      document.body.style["-webkit-transform"] = "scale(" + 1 + ")";
      document.body.style["-moz-transform"] = "scale(" + 1 + ")";
      document.getElementsByTagName("body")[0].style = {
        "-moz-transform": "scale(1)",
        "-webkit-transform": "scale(1)",
        "-o-transform": "scale(1)",
        "-ms-transform": "scale(1)",
      };
    },
  },
};
