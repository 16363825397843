import axios from "axios";
import auth0 from "@/auth/auth0";

let axiosInstance = axios.create({
  headers: {
    common: {
      Accept: "text/plain, *!/!*",
    },
    withCredentials: true,
  },
  timeout: 50000,
});
export default axiosInstance;

// interceptor to catch errors
const errorInterceptor = function (error) {
  // all the error responses
  console.log("Error: ", error);
  if (error?.response?.status) {
    switch (error.response.status) {
      case 400:
        console.error(error.response.status, error.message);
        break;

      case 401: // authentication error
        console.log("Login redirect");
        var url = new URL("https://app.smartbricks.at");

        if (window) {
          url = window.location.protocol + "//" + location.host + "/";
          // url = window.location.href.match(/.*\//)[0] + "empty?page=" + window.location.href.replace(/.*\//g, "");
        }
        auth0.loginWithRedirect({
          // ssr: no window here!
          redirect_uri: url,
        });
        return Promise.resolve();

      default:
        console.error(error.response.status, error.message);
    }
    console.error("Error: ", error);
  }
  return Promise.reject(error);
};

const authInterceptor = async (config) => {
  try {
    config.headers["Authorization"] = "Bearer " + (await auth0.getTokenSilently());
  } catch (error) {
    console.log("Try without token");
  }

  return config;
};

// Add a request interceptor
axiosInstance.interceptors.request.use(authInterceptor);
axiosInstance.interceptors.response.use((response) => response, errorInterceptor);

function prepareSearchObject(searchObject) {
  let searchParams = new URLSearchParams();
  for (let name in searchObject) {
    let member = searchObject[name];
    if (member == null) continue;
    if (Array.isArray(member)) {
      member.forEach((value) => searchParams.append(name, value));
    } else {
      searchParams.append(name.toString(), member);
    }
  }
  return searchParams;
}
export { prepareSearchObject };

// eslint-disable-next-line no-unused-vars
function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
