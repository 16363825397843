<!--
project.vue
Created for smartbricksConfigurators
by Tristan Martin


-->
<template>
  <v-app>
    <!-- v-if="!isAnyTemplateSelected" -->
    <v-app-bar v-if="!isAnyTemplateSelected" elevate-on-scroll fixed class="app-bar">
      <v-btn class="px-5" icon nuxt to="/">
        <v-icon>mdi-home-outline</v-icon>
      </v-btn>
      <v-divider class="ml-1" vertical></v-divider>
      <div v-for="(page, index) in filteredPagesAppBar" :key="index" class="d-flex justify-space-around w-100 px-5">
        <v-btn v-ripple="false" large text flat :to="page.link" nuxt link>
          <v-icon class="mr-1">
            {{ page.icon }}
          </v-icon>
          <span class="text-capitalize">{{ page.title }}</span>
        </v-btn>
      </div>
      <v-spacer></v-spacer>
      <div
        v-for="(page, index) in filteredPagesAppBarD"
        :key="'desktop-' + index"
        class="d-flex justify-space-around w-100 px-5 align-center"
        style="background-color: #2a9189; height: 100% !important"
      >
        <v-btn v-ripple="false" large text flat :to="page.link" nuxt link>
          <v-icon large color="#ffffff" class="mr-1">
            {{ page.icon }}
          </v-icon>
          <span style="color: #ffffff" class="text-capitalize text-body-1">{{ page.title }}</span>
        </v-btn>
      </div>
    </v-app-bar>
    <div v-if="validProject">
      <nuxt></nuxt>
      <v-bottom-navigation
        v-show="safeBreakpoint.smAndDown && !isAnyTemplateSelected"
        :value="safeFullPath"
        height="75"
        grow
        app
        color="primary"
      >
        <v-btn
          v-for="(page, index) in pages.filter((p) => !p.desktopOnly)"
          :key="index"
          v-ripple="false"
          :value="page.link"
          nuxt
          link
          :to="page.link"
        >
          <span>{{ page.title }}</span>
          <v-icon>
            {{ page.icon }}
          </v-icon>
        </v-btn>
        <div
          v-for="(page, index) in filteredDesktopOnlyPages"
          :key="'desktop-' + index"
          class="d-flex justify-space-around w-100 align-center"
          style="background-color: #2a9189; height: 100% !important"
        >
          <v-btn v-ripple="false" large text flat :to="page.link" nuxt link>
            <span style="color: #ffffff" class="text-capitalize">{{ page.title }}</span>
            <v-icon color="#ffffff">
              {{ page.icon }}
            </v-icon>
          </v-btn>
        </div>
      </v-bottom-navigation>
    </div>
    <div v-else>
      <v-main> Not a valid project </v-main>
    </div>
  </v-app>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
// import newProjectMainSidebar from "@/components/core/bars/newProjectMainSidebar.vue";
import appModeMixin from "@/mixins/appModeMixin.vue";
import googleMapsMixin from "@/mixins/googleMapsMixin.js";
import breakpointsMixin from "@/mixins/breakpointsMixin.js";

export default {
  name: "Project",
  components: {
    // newProjectMainSidebar,
  },
  mixins: [appModeMixin, googleMapsMixin, breakpointsMixin],
  data: () => ({
    validProject: true,
    loadingProject: true,
  }),
  computed: {
    ...mapState("project", ["activeProject", "pathStackLength"]),
    ...mapState("smartNotes", ["isAnyTemplateSelected"]),
    ...mapGetters("smartNotes", ["getIsAnyTemplateSelected"]),
    safeFullPath() {
      return process.client ? this.$route.fullPath : "";
    },
    safeQueryId() {
      return process.client ? this.$route.query.id : 0;
    },
    defaultPages: function () {
      return [
        {
          title: "Projekt",
          icon: "mdi-file-document-outline",
          link: "/projectStartOverview?id=" + this.safeQueryId,
        },
        {
          title: "Dokumente",
          icon: "mdi-folder-outline",
          link: "/cloudView?id=" + this.safeQueryId,
        },
        {
          title: "Protokolle",
          icon: "mdi-note-outline",
          link: "/smartNotesPage?id=" + this.safeQueryId,
        },
      ];
    },
    planPage() {
      return {
        title: "Planen",
        icon: "mdi-floor-plan",
        link: "/blueprint?id=" + this.safeQueryId,
        // target: "_blank",
        desktopOnly: true,
      };
    },
    positionsPage() {
      return {
        title: "Positionen",
        icon: "mdi-format-list-checkbox",
        link: "/quantitySurveyPositions?id=" + this.safeQueryId,
      };
    },
    creatingProject() {
      const urlContainsProject = this.safeFullPath.includes("projectTypeId=");
      return urlContainsProject ? this.headerText : "Failed";
    },
    pages: function () {
      if (!this.validProject || this.loadingProject) {
        return [];
      }

      switch (this.activeProject.type) {
        case 0: {
          return this.defaultPages.concat([this.planPage]);
        }
        case 1: {
          return this.defaultPages;
        }
        case 2: {
          return this.defaultPages.concat([this.positionsPage, this.planPage]);
        }
        default:
          return [];
      }
    },
    headerText() {
      if (this.projectTypeId === 0) {
        return "Neues Projekt anlegen";
      } else if (this.projectTypeId === 1) {
        return "Neuen Kleinauftrag anlegen";
      } else if (this.projectTypeId === 2) {
        return "Neues Aufmaß anlegen";
      } else {
        return "Unbekannter Projekttyp";
      }
    },
    projectIcon() {
      if (this.projecTypeId === 0) {
        return "mdi-file-document-edit-outline ";
      } else if (this.projectTypeId === 1) {
        return "mdi-clipboard-check-outline";
      } else {
        return "mdi-tape-measure";
      }
    },
    showAppBar() {
      const path = this.safeFullPath;
      return !(path.includes("/blueprint?id=") || path.includes("/blueprint/?id="));
    },
    filteredDesktopOnlyPages() {
      if (!this.isClient) return [];
      if (this.safeBreakpoint.width > 300) {
        return this.pages.filter((p) => p.desktopOnly);
      }
      return [];
    },
    filteredPagesAppBar() {
      if (!this.isClient) return [];

      if (this.safeBreakpoint.width > 960) {
        return this.pages.filter((p) => !p.desktopOnly);
      }
      return [];
    },
    filteredPagesAppBarD() {
      if (!this.isClient) return [];

      if (this.safeBreakpoint.width > 960) {
        return this.pages.filter((p) => p.desktopOnly);
      }
      return [];
    },
    showBottomNavigation() {
      return this.pathStackLength === 0 && this.$vuetify.breakpoint.smAndDown && !this.isAnyTemplateSelected;
    },
  },
  async mounted() {
    if (this.$route?.query?.id == null || this.safeQueryId === 0) {
      this.validProject = false;
    }

    try {
      await this.setActiveProjectById(this.safeQueryId);
    } catch (error) {
      console.error(error);
      this.validProject = false;
    } finally {
      this.loadingProject = false;
    }
  },
  methods: {
    ...mapActions("project", ["setActiveProjectById"]),
    navigateTo(path) {
      this.$router.push(path);
    },
  },
};
</script>

<style scoped>
.app-bar {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  background-color: white;
}
.app-bar > :first-child {
  padding-right: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
</style>
