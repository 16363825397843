<!--
appModeMixin.vue
Created for smartbricksConfigurators
by Tristan Martin


-->
<script>
import Repository from "@/Repository/Repository";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "AppModeMixin",
  data: () => ({
    appModes: [
      {
        value: "LOCAL",
        text: "Local Build",
      },
      {
        value: "DEVELOPER",
        text: "Developer Build",
      },
      {
        value: "PRODUCTION",
        text: "",
      },
    ],
    defaultAppMode: {
      value: "UNKNOWN",
      color: "pink darken-4",
      text: "Unknown Build",
    },
    baseUrlModes: [
      {
        value: "LOCAL SERVER",
        color: "cyan darken-2",
        btnIndex: 0,
      },
      {
        value: "DEVELOPER SERVER",
        color: "orange darken-2",
        btnIndex: 1,
      },
      {
        value: "PRODUCTION SERVER",
        color: "red darken-2",
        btnIndex: 2,
      },
    ],
    defaultBaseUrlMode: {
      value: "UNKNOWN",
      color: "black",
      btnIndex: undefined,
    },
  }),
  computed: {
    ...mapGetters("general", ["getServerBaseUrl"]),
    appMode: function () {
      const envName = this.$config.appMode;
      const foundAppMode = this.appModes.find((am) => am.value === envName);
      if (foundAppMode == null) {
        return this.defaultAppMode;
      } else return foundAppMode;
    },
    baseUrl: {
      get: function () {
        return this.activeBaseUrl.btnIndex;
      },
      set: function (index) {
        const baseUrlMode = this.baseUrlModes.find((bum) => bum.btnIndex === index);
        if (baseUrlMode == null) {
          this.changeUrl("");
        } else {
          this.changeUrl(baseUrlMode.value);
        }
      },
    },
    activeBaseUrl: function () {
      const baseUrlValue = this.getServerBaseUrl;
      const foundBaseUrlMode = this.baseUrlModes.find((am) => am.value === baseUrlValue);
      if (foundBaseUrlMode == null) {
        return this.defaultBaseUrlMode;
      } else return foundBaseUrlMode;
    },
  },
  created() {
    switch (this.appMode.value) {
      case "LOCAL": {
        // If local, we use the base Url from the store (which can be chosen)
        this.changeUrl(this.getServerBaseUrl);
        break;
      }
      // If not local, use the BaseUrl from Environment variable (production/development, stored on server)
      case "PRODUCTION":
      case "DEVELOPER":
      default: {
        Repository.defaults.baseURL = this.$config.baseUrl;
        break;
      }
    }
  },
  methods: {
    ...mapActions("general", ["setServerBaseUrl"]),
    changeUrl(name) {
      this.setServerBaseUrl(name);
      switch (name) {
        case "LOCAL SERVER": {
          console.log("URL", this.$config.baseUrlLocal);
          Repository.defaults.baseURL = this.$config.baseUrlLocal;
          break;
        }
        case "DEVELOPER SERVER": {
          Repository.defaults.baseURL = this.$config.baseUrlDeveloper;
          break;
        }
        case "PRODUCTION SERVER": {
          Repository.defaults.baseURL = this.$config.baseUrlProduction;
          break;
        }
        default: {
          Repository.defaults.baseURL = this.$config.baseUrlLocal;
          break;
        }
      }
    },
  },
};
</script>

<style scoped></style>
