<!--
enums.vue
Created for smartbricksConfigurators
by Tristan Martin


-->
<script>
import _ from "lodash";
export default {
  name: "Enums",
  data: () => ({
    companyEditTypes: [
      {
        id: 0,
        name: "-",
      },
      {
        id: 1,
        name: "Nur Lesen",
      },
      {
        id: 2,
        name: "Schreiben",
      },
    ],
    customerTypes: [
      {
        id: 0,
        name: "Privatkunde",
      },
      {
        id: 1,
        name: "Firmenkunde",
      },
    ],
    projectStatus: [
      {
        id: 0,
        name: "Neu erstellt",
        color: "cyan",
      },
      {
        id: 1,
        name: "In Bearbeitung",
        color: "orange",
      },
      {
        id: 2,
        name: "In Kalkulation",
        color: "red",
      },
      {
        id: 5,
        name: "Archiv",
        color: "grey",
      },
    ],
    projectTypes: [
      {
        id: 0,
        name: "Angebot",
        displayName: "Neues Angebot",
        icon: "mdi-file-document-edit-outline",
        color: "#E1EDDD",
      },
      {
        id: 1,
        name: "Kleinauftrag",
        displayName: "Neuer Kleinauftrag",
        icon: "mdi-clipboard-check-outline",
        color: "#CCD8EF",
      },
      {
        id: 2,
        name: "Aufmaß",
        displayName: "Neues Aufmaß",
        icon: "mdi-tape-measure",
        color: "#E6A942",
      },
    ],
    importActions: [
      {
        id: 0,
        name: "Unbekannt",
        color: "grey lighten-2",
      },
      {
        id: 1,
        name: "Neu",
        color: "cyan lighten-2",
      },
      {
        id: 2,
        name: "Ändern",
        color: "orange lighten-2",
      },
      {
        id: 3,
        name: "Keine Änderung",
        color: "white",
      },
      {
        id: 4,
        name: "Kein Import",
        color: "green",
      },
    ],
    alternativeCalculationType: [
      {
        id: 0,
        name: "Disabled",
      },
      {
        id: 1,
        name: "In Dialog",
      },
      {
        id: 2,
        name: "In Karte",
      },
    ],
    exportTypes: [
      {
        name: "Leer",
        id: 1,
        needsConfiguration: false,
      },
      {
        name: "ONLV",
        id: 2,
        needsConfiguration: true,
      },
      {
        name: "ONLV/Hauptgruppen",
        id: 3,
        needsConfiguration: true,
      },
      {
        name: "PDF/Kostenschätzungsbericht",
        id: 5,
        needsConfiguration: true,
      },
      {
        name: "PDF/Massenzusammenfassung",
        id: 17,
        needsConfiguration: false,
      },
      {
        name: "CSV/Massenzusammenfassung",
        id: 8,
        needsConfiguration: false,
      },
      {
        name: "HTML/Preview/Massenzusammenfassung",
        id: 20,
        needsConfiguration: false,
      },
    ],
  }),
  methods: {
    getName(array, id) {
      if (!_.isArray(array)) {
        return "Error in evaluating this value.";
      }
      const entry = array.find((arr) => arr.id === id);
      if (entry != null) {
        return entry.name;
      }

      return "Error: Value not found";
    },
    get(array, id, prop) {
      if (!_.isArray(array)) {
        return "Error in evaluating this value.";
      }
      const entry = array.find((arr) => arr.id === id);
      if (entry != null) {
        return entry[prop];
      }

      return "Error: Value not found";
    },
    isType(array, foundId, checkName) {
      if (!_.isArray(array)) {
        return false;
      }

      const entry = array.find((arr) => arr.id === foundId);
      if (entry != null) {
        return entry.name === checkName;
      }

      return false;
    },
  },
};
</script>

<style scoped></style>
