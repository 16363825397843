<template>
  <v-app style="background-color: white">
    <!-- v-if="$vuetify.breakpoint.mdAndUp" -->
    <v-app-bar
      style="border-bottom: 1px solid #c2c2c2; z-index: 9; background-color: white"
      elevate-on-scroll
      fixed
      class="app-bar"
    >
      <v-btn class="px-5" icon nuxt to="/">
        <v-icon color="#000000">mdi-home-outline</v-icon>
      </v-btn>
      <v-divider class="ml-1" vertical></v-divider>
      <div v-if="projectType" class="ml-1 app-bar-content d-flex align-center">
        <div>
          <v-icon color="#000000">{{ projectType.icon }}</v-icon>
          <!-- <span class="font-weight-medium">{{ projectText }}</span> -->
          <span class="font-weight-medium">{{ projectType.displayName }}</span>
        </div>
        <!-- <v-spacer />
        <div
          v-if="$vuetify.breakpoint.mdAndUp"
          class="d-flex justify-space-around w-100 align-center"
          style="background-color: #2a9189; height: 100% !important"
        >
          <v-btn v-ripple="false" large text flat :to="planPage.link" nuxt link>
            <v-icon large color="#ffffff" class="mr-1">
              {{ planPage.icon }}
            </v-icon>
            <span style="color: #ffffff" class="text-capitalize text-body-1">{{ planPage.title }}</span>
          </v-btn>
        </div> -->
      </div>
      <v-spacer />
      <v-tooltip left>
        <template #activator="{ on, attrs }">
          <!-- :disabled="project.type === 2" -->
          <!-- v-if="activeProject.id !== 0" -->
          <v-btn v-if="activeProject.id !== 0" v-bind="attrs" icon v-on="on" @click="openMaps">
            <v-icon>mdi-directions</v-icon>
          </v-btn>
        </template>
        <span>Maps</span>
      </v-tooltip>
    </v-app-bar>
    <v-main>
      <nuxt />
    </v-main>
  </v-app>
</template>

<script>
import appModeMixin from "@/mixins/appModeMixin";
import googleMapsMixin from "@/mixins/googleMapsMixin";
import enums from "@/mixins/enums";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  name: "AddNewProject",
  components: {},
  mixins: [appModeMixin, googleMapsMixin],
  data() {
    return {};
  },
  computed: {
    ...mapState("project", {
      projectTypeId: (state) => state.activeProject.projectTypeId,
    }),
    projectTypeId() {
      return this.activeProject.projectTypeId;
    },
    projectType() {
      const projectTypeId = this.activeProject.projectTypeId;
      return enums.data().projectTypes.find((pt) => pt.id === projectTypeId);
    },
    // projectText() {
    //   if (this.projectType.id === 0 || this.projectType.id === 2) {
    //     return "Neues";
    //   } else {
    //     return "Neuer";
    //   }
    // },
    planPage() {
      return {
        title: "Projekt anlegen",
        icon: "mdi-chevron-right",
        link: "/blueprint?id=" + this.$route.query.id,
        // target: "_blank",
        desktopOnly: true,
      };
    },
  },
  mounted() {
    if (this.$route.query.id) {
      console.log("route query id: ", this.$route.query.id);
      try {
        this.setActiveProjectById(this.$route.query.id);
      } catch (error) {
        console.error("Failed to set the project ID", error);
      }
    } else {
      console.log("No active project found");
    }
  },
  methods: {
    ...mapActions("project", ["setActiveProjectById"]),
    ...mapActions("smartNotes", ["updateIsAnyTemplateSelected"]),
  },
};
</script>

<style scoped>
.app-bar-content {
  width: 100% !important;
}
</style>
