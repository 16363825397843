/*
    construction.js

    Holds information about a construction with several attributes.

 */
import Vue from "vue";

import ConstrutionRepository from "@/Repository/Construction.api";

const getDefaultState = () => {
  return {
    constructions: [],
    constructionAttributes: [],
    constructionsLoading: false,
  };
};

export const state = getDefaultState;

const getters = {
  // getter to retrieve a construction attribute by the given ID
  getConstructionAttributeByAttributeId: (state) => (attributeId) => {
    console.log("getConstructionAttributeByAttributeId", attributeId, state.constructionAttributes);
    return state.constructionAttributes.find((ca) => ca.attributeId === attributeId);
  },
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  addAttributes(state, constructionAttributes) {
    constructionAttributes.forEach((ca) => {
      state.constructionAttributes.push(ca);
    });
  },
  updateAttribute(state, attribute) {
    const index = state.constructionAttributes.findIndex((ca) => ca.id === attribute.id);
    state.constructionAttributes.splice(index, 1, attribute);
  },
  addConstruction(state, construction) {
    construction.visible = true;
    state.constructions.push(construction);
  },
  updateConstruction(state, construction) {
    construction.visible = true;
    const foundConstructionIndex = state.constructions.findIndex((cwid) => cwid.id === construction.id);
    state.constructions.splice(foundConstructionIndex, 1, construction);
  },
  deleteConstruction(state, blueprintId) {
    let foundConstructionIndex = state.constructions.findIndex((cwid) => cwid.blueprintId === blueprintId);
    state.constructions.splice(foundConstructionIndex, 1);
  },
  toggleVisibility(state, constructionId) {
    let index = state.constructions.findIndex((cwid) => cwid.id === constructionId);

    Vue.set(state.constructions[index], "visible", !state.constructions[index].visible);
  },
  setConstructionsLoading(state, status) {
    state.constructionsLoading = status;
  },
};

const actions = {
  resetState: (context) => {
    context.commit("resetState");
  },
  updateConstruction: async function (context, construction) {
    const result = await ConstrutionRepository.put(construction);

    if (result.status !== 200 && result.status !== 201) {
      console.error("Could not update Construction");
      return;
    }

    context.commit("updateConstruction", result.data);
  },
  loadConstructionsFromDatabase: async function (context) {
    context.commit("setConstructionsLoading", true);
    let result = await ConstrutionRepository.get(context.rootState.project["activeProject"].id);
    let constructions = result.data;
    for (let construction of constructions) {
      context.commit("addConstruction", construction);

      context.commit("addAttributes", construction.attributes);
    }
    context.commit("setConstructionsLoading", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
