/*
    configuration.js

    Holds information about configurations of one constructionId.
    configurationWithId array has the following structure:
    configurationWithId: {
      constructionId: <Number>
      configurations: ConfigurationObject
    }
 */
import _ from "lodash";
import Vue from "vue";
import ConfigurationRepository from "@/Repository/Configuration.api";

const getDefaultState = () => ({
  configurations: [],
  configurationsLoading: false,
});

const state = getDefaultState();

const getters = {};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  addConfiguration(state, configuration) {
    state.configurations.push(configuration);
  },
  updateConfiguration(state, { configuration, index }) {
    state.configurations.splice(index, 1, configuration);
  },
  deleteConfiguration(state, configurationId) {
    let index = state.configurations.findIndex((cwid) => cwid.id === configurationId);
    state.configurations.splice(index, 1);
  },
  setConfigurationsLoading(state, status) {
    state.configurationsLoading = status;
  },
};

const actions = {
  resetState: (context) => context.commit("resetState"),
  /**
   * Deletes a configuration from the store.
   */
  deleteConfigurationsOfConstructionId: (context, constructionId) => {
    context.commit("setConfigurationsLoading", true);
    let configurations = context.state["configurations"].filter((conf) => conf.constructionId === constructionId);

    for (let configuration of configurations) {
      if (configuration.id !== 0) {
        context.commit("deleteConfiguration", configuration.id);
      }
    }

    context.commit("setConfigurationsLoading", false);
  },
  loadConfigurationFromDatabase: async function (context) {
    context.commit("setConfigurationsLoading", true);

    let result = await ConfigurationRepository.getOfProject(context.rootState.project["activeProject"].id);
    if (result.status !== 200 && result.status !== 201) {
      console.error("Could not load Configuration");
      return;
    }

    for (let configuration of result.data) {
      context.commit("addConfiguration", configuration);
    }

    context.commit("setConfigurationsLoading", false);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
