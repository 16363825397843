import axios from "axios";
import html2canvas from "html2canvas";
import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState("project", ["activeProject"]),
    ...mapGetters("project", {
      activeProject: "getActiveProject",
    }),
    googleMapsUrl() {
      var lat = parseFloat(this.activeProject.coordinates.split(",")[0].split(":")[1].trim());
      var lng = parseFloat(this.activeProject.coordinates.split(",")[1].split(":")[1].trim());
      console.log(lat, lng);
      const address = encodeURIComponent(lat + ", " + lng);
      return `https://www.google.com/maps/search/?api=1&query=${address}`;
    },
    appleMapsUrl() {
      var lat = parseFloat(this.activeProject.coordinates.split(",")[0].split(":")[1].trim());
      var lng = parseFloat(this.activeProject.coordinates.split(",")[1].split(":")[1].trim());
      console.log(lat, lng);
      const address = encodeURIComponent(lat + ", " + lng);
      return `https://maps.apple.com/?q=${address}`;
    },
  },
  methods: {
    async getAddressFromLatLng(pos) {
      const lat = pos.lat;
      const lng = pos.lng;
      console.log(typeof lat, typeof lng, "LAT LNG");

      // Check if lat and lng are functions
      if (typeof lat === "function" && typeof lng === "function") {
        // Perform the action when lat and lng are functions
        let response = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            lat() +
            "," +
            lng() +
            "&key=" +
            this.$config.googleMapsApiKey
        );
        console.log("%c" + response.data.results[0].formatted_address, "color: green");
        if (response.data.error_message) {
          return null;
        } else {
          return response.data.results[0].formatted_address;
        }
      } else if (typeof lat === "number" && typeof lng === "number") {
        // Perform the action when lat and lng are NOT functions
        this.response = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
            lat +
            "," +
            lng +
            "&key=" +
            this.$config.googleMapsApiKey
        );
      }
    },
    async getLatLngFromAddress(address) {
      try {
        let response = await axios.get(
          "https://maps.googleapis.com/maps/api/geocode/json?address=" +
            address +
            "&key=" +
            this.$config.googleMapsApiKey
        );
        if (response.data.error_message) {
          return null;
        } else {
          return response.data.results[0].geometry.location;
        }
      } catch (e) {
        console.warn("can't get data from google maps!", e);
        return null;
      }
    },
    // helpful string operations to make code less ugly
    coordinatesToString(coo) {
      return "lat: " + coo.lat + ", lng: " + coo.lng;
    },
    contactToAddress(contact) {
      return contact.street + ", " + contact.zipCode + " " + contact.town + ", Österreich";
    },
    mapSnapshotInit() {
      this.getScaleValue();
      this.getSnapshotOfElement("#googleMap", 0, 0, "85vw", "65vh", (base64MapData) => {
        this.snapshotImageData = base64MapData;
      });
      this.snapshotIsLoading = true;
    },
    async getSnapshotOfElement(element, posX, posY, width, height, convertedSnapshotCallback) {
      this.hideZoomButtons();
      try {
        // scale 1, usind devicePixelRatio leads to wrong image size on 2k and 4k screens
        const canvas = await html2canvas(document.querySelector(element), {
          useCORS: true,
          allowTaint: false,
          scale: 1,
        });

        const context = canvas.getContext("2d");
        const imageData = context.getImageData(posX, posY, canvas.width, canvas.height).data;
        const outputCanvas = document.createElement("canvas");
        const outputContext = outputCanvas.getContext("2d");
        outputCanvas.width = canvas.width;
        outputCanvas.height = canvas.height;

        const outputIData = outputContext.createImageData(canvas.width, canvas.height);
        outputIData.data.set(imageData);
        outputContext.putImageData(outputIData, 0, 0);

        convertedSnapshotCallback(outputCanvas.toDataURL());

        this.imageUploaded = true;
        this.snapshotIsLoading = false;
        this.image = this.snapshotImageData;
      } catch (error) {
        this.errorOccured = true;
        this.showFailedMessage(
          "Es ist ein Fehler aufgetreten. Versuchen Sie es erneut, und wenn der Fehler weiterhin besteht, wenden Sie sich an den Support!"
        );
      }
    },
    clearMapSnapshot() {
      this.snapshotImageData = null;
    },
    getScaleValue() {
      let zoom = this.map.zoom;
      this.scale = (156543.03392 * Math.cos((this.map.center.lat() * Math.PI) / 180)) / Math.pow(2, zoom);
      this.minScale = Math.floor(this.scale * this.minScaleWidth);
      this.maxScale = Math.ceil(this.scale * this.maxScaleWidth);
      let scaleInfo = {
        scaleWidth: 0,
        scaleReferenceLength: 0,
      };
      // Loop through scale values
      for (let i = 0; i < this.scaleValues.length; i++) {
        if (i !== this.scaleValues.length - 1) {
          // Select appropriate scale value
          if (
            (this.minScale <= this.scaleValues[i].val && this.scaleValues[i].val < this.maxScale) ||
            (this.minScale >= this.scaleValues[i].val && this.maxScale < this.scaleValues[i].val)
          ) {
            this.setScaleValues(this.scale, this.scaleValues[i]);
            scaleInfo.scaleWidth = this.scaleWidth;
            scaleInfo.scaleReferenceLength = this.scaleValues[i].val;
          }
        }
      }
      return scaleInfo;
    },
    setScaleValues(scale, values) {
      this.scaleWidth = values.val / scale;
    },
    openMaps() {
      if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
        window.location = this.appleMapsUrl;
        const startTime = new Date().getTime();
        setTimeout(() => {
          if (new Date().getTime() - startTime < 1000) {
            window.location = this.googleMapsUrl;
          }
        }, 1000);
      } else {
        window.open(this.googleMapsUrl);
      }
    },
  },
};
