import Vue from "vue";
import Vuex from "vuex";
import mainConfiguration from "./configuration";
import mainConstruction from "./construction";
import project from "./project";
import company from "./company";
import general from "./general";
import blueprint from "./blueprint";
import groups from "@/store/groups.store";

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    configuration: mainConfiguration,
    mainConstruction,
    company,
    general,
    project,
    blueprint,
    groups,
  },
  plugins: [],
});

export const strict = true;
