/* plugins/google-analytics.js */

/* eslint-disable */
(function() {
    window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); }
    gtag('js', new Date());
    gtag('config', 'G-CHCC32JDVZ');
  })();
  /* eslint-enable */
