/*
    general.js

    Holds general information about the whole project.

 */
const getDefaultState = () => {
  return {
    serverBaseUrl: "LOCAL",
    // used to indicate an unsaved state
    dirty: false,
  };
};

const state = getDefaultState;

const getters = {
  getServerBaseUrl: (state) => state.serverBaseUrl,
};

const mutations = {
  setServerBaseUrl(state, value) {
    state.serverBaseUrl = value;
  },
};

const actions = {
  setServerBaseUrl: function (context, value) {
    context.commit("setServerBaseUrl", value);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
