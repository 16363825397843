import Repository from "./Repository";
const resource = "customers";
export default {
  getCreateProject: () => Repository.get(`${resource}`),

  post: (formData) => Repository.post(`${resource}`, formData),

  put: (formData) => Repository.put(`${resource}`, formData),

  delete: (id) => Repository.delete(`${resource}/${id}`),
};
