import Repository, { prepareSearchObject } from "./Repository";
const resource = "documents";

export default {
  async post(formData) {
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        /*"Cache-Control": null,
        "X-Requested-With": null,
        withCredentials: true*/
      },
    };
    return Repository.post(`${resource}`, formData, config);
  },

  getById: (id) => Repository.get(`${resource}/${id}`),

  uploadProject: (formFile, params) =>
    Repository.post(`${resource}/upload-project?${prepareSearchObject(params)}`, formFile, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),

  uploadSmartNote: (formFile, params) =>
    Repository.post(`${resource}/upload-smartnote?${prepareSearchObject(params)}`, formFile, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }),

  getByProjectId: (projectId) => Repository.get(`${resource}/byProject/${projectId}`),
  getBySmartNoteId: (smartNoteId) => Repository.get(`${resource}/bySmartNote/${smartNoteId}`),
  getSignedUrl: () => Repository.get(`${resource}/signedUrl`),

  deleteFile: (id) => Repository.delete(`${resource}/${id}`),
};
