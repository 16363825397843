import Repository from "./Repository";

const resource = "smart-note-groups";

export default {
  async get(templateID, activeProjectId) {
    if (templateID == null || templateID == 0) {
      throw new Error("TemplateId is null or 0, this request is only allowed for a specific template!");
    }
    if (activeProjectId == null || activeProjectId == 0) {
      throw new Error("ActiveProjectId is null or 0, this request is only allowed for a specific project!");
    }

    const response = await Repository.get(`${resource}?SmartNoteTemplateId=${templateID}&ProjectId=${activeProjectId}`);
    return response;
  },

  async getById(id) {
    const response = await Repository.get(`${resource}/${id}`);
    return response;
  },

  async post(formData) {
    const response = await Repository.post(`${resource}`, formData);
    return response;
  },

  async postAsync(formData) {
    const response = await Repository.post(`${resource}`, formData);
    return response.data;
  },

  async putAsync(formData) {
    const response = await Repository.put(`${resource}`, formData);
    return response.data;
  },

  // async putAsync(id, formData) {
  //   if (id == null || id == 0) {
  //     throw new Error("Id is null or 0, this request is only allowed for a specific id!");
  //   }
  //   const response = await Repository.put(`${resource}/${id}`, formData);
  //   return response.data;
  // },

  async delete(id) {
    if (id == null || id == 0) {
      throw new Error("Id is null or 0, this request is only allowed for a specific id!");
    }
    const response = await Repository.delete(`${resource}/${id}`);
    return response;
  },
};
