import Repository from "@/Repository/Repository";

const resource = "groups";
export default {
  get: (projectId) => Repository.get(`${resource}?ProjectId=${projectId}`),

  post: (data) => Repository.post(`${resource}`, data),

  put: (data) => Repository.put(`${resource}`, data),

  delete: (id) => Repository.delete(`${resource}/${id}`),
};
