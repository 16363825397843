<template>
  <div>
    <nuxt />
  </div>
</template>

<script>
export default {
  name: "Empty",
};
</script>
<style>
html {
  overflow: hidden;
  overscroll-behavior: none;
}
</style>
<style scoped></style>
