import Vue from "vue";

import Vuetify from "vuetify/lib";
import "vuetify/dist/vuetify.min.css";
import "@mdi/font/css/materialdesignicons.css"; // Ensure you are using css-loader
import "@fortawesome/fontawesome-free/css/all.css";
Vue.use(Vuetify);

export default (ctx) => {
  const vuetify = new Vuetify({
    icons: {
      iconfont: "mdi",
    },
    /*theme: {
      dark: false // From 2.0 You have to select the theme dark or light here
    }*/
    theme: {
      themes: {
        dark: {
          primary: "#8c231d",
          // primary: "#077893",
          secondary: "#333d40",
          eyeCatcher: "#f5c42d",
          confirm: "#2A9189",
        },
        light: {
          primary: "#8c231d",
          // primary: "#077893",
          secondary: "#333d40",
          eyeCatcher: "#f5c42d",
          confirm: "#4A9488",
        },
      },
    },
  });

  ctx.app.vuetify = vuetify;
  ctx.$vuetify = vuetify.framework;
};
