import Repository from "./Repository";
const resource = "constructions";
export default {
  get: (projectId) => Repository.get(`${resource}?ProjectId=${projectId}`),

  post: (formData) => Repository.post(`${resource}`, formData),

  put: (formData) => Repository.put(`${resource}`, formData),

  delete: (id) => Repository.delete(`${resource}/${id}`),
};
