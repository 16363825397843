<template>
  <!--  gradient colors from: https://cssgradient.io/-->
  <!--  <v-app style="background: #ffffff;">-->
  <v-app style="background-color: white">
    <nuxt />
  </v-app>
</template>

<script>
import { mapActions } from "vuex";
import { change } from "@/Repository/Repository";
import auth from "../mixins/authGuard";
import appModeMixin from "@/mixins/appModeMixin";

export default {
  components: {},
  mixins: [auth, appModeMixin],
  data() {
    return {
      clipped: false,
      drawer: true,
      fixed: false,
      items: [
        { icon: "apps", title: "Welcome", to: "/" },
        { icon: "bubble_chart", title: "Inspire", to: "/inspire" },
      ],
      miniVariant: false,
      right: true,
      rightDrawer: false,
      title: "Vuetify.js",
    };
  },
  created() {},
  mounted() {
    this.loadCompany();
  },
  methods: {
    ...mapActions("company", ["loadCompany"]),
    goToHome() {
      this.$router.push("/");
    },
  },
};
</script>
<style>
html {
  overflow: auto;
  overscroll-behavior: initial;
}
</style>
<style scoped>
.userMenu {
  position: absolute;
  right: 0px;
  align-self: end;
  padding-right: 10px;
}
.logo {
  cursor: pointer;
}
.background {
  background: linear-gradient(
    90deg,
    rgba(145, 145, 153, 1) 0%,
    rgba(195, 195, 199, 1) 35%,
    rgba(174, 179, 180, 1) 100%
  );
}
@media (max-width: 1264px) {
  .container-wrapper {
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
}

body::-webkit-scrollbar {
  display: none;
}
::-webkit-scrollbar-track {
  background-color: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(85, 85, 85, 0.67);
}
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(85, 85, 85, 0.67);
}
</style>
