import Repository, { prepareSearchObject } from "./Repository";
const resource = "projects";
export default {
  get: (params) => Repository.get(`${resource}?${prepareSearchObject(params)}`),
  getByIdAsync: (id) => Repository.get(`${resource}/${id}`),
  calculateCost: (id) => Repository.get(`${resource}/${id}/calculate`),
  delete: (id) => Repository.delete(`${resource}/${id}/configurator`),
  postAsync: (formData) => Repository.post(`${resource}`, formData),
  postQuantitySurvey: (file) => Repository.post(`${resource}/quantity-survey`, file),
  putAsync: (formData) => Repository.put(`${resource}`, formData),
  updateStatus: (formData) => Repository.put(`${resource}/project-view`, formData),
  export: (id, exportType, filename) => Repository.get(`${resource}/${id}/export/${exportType}?filename=${filename}`),
  exportAndDownload: (id, exportType, filename) =>
    Repository({
      url: `${resource}/${id}/export/${exportType}?filename=${filename}`,
      method: "GET",
      responseType: "blob",
    }),
  share: (formData) => Repository.post(`${resource}/share`, formData),
};
