<template>
  <v-app>
    <nuxt />
  </v-app>
</template>

<script>
export default {
  name: "Error",
  components: {},
  mixins: [],

  data: () => ({}),
  computed: {},
  mounted() {},
  methods: {},
};
</script>

<style scoped></style>
