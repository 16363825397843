// initial state
import ProjectRepository from "../Repository/Project.api";
import smartNoteTemplateAPI from "@/Repository/SmartNoteTemplate.api.js";

const defaultProject = () => ({
  id: 0,
  name: "",
  customer: {
    id: 0,
    contact: {
      id: 0,
      communication: {
        id: 0,
        street: "",
        zipCode: "",
        town: "",
        country: "",
      },
    },
    type: 0,
  },
  coordinates: "",
  projectTypeId: 0,
});

const getDefaultState = () => {
  return {
    activeProject: defaultProject(),
    projectsLoading: false,
    projectPrice: 0,
    projects: [],
    coordinates: "",
    templates: [],
    pathStackLength: 0,
  };
};
// initial state
const state = getDefaultState;

const getters = {
  getActiveProject: (state) => state.activeProject,
  getTemplates: (state) => state.templates,
  getProjectTypeId: (state) => state.projectTypeId,
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState);
  },
  setProject(state, project) {
    // state.activeProject = project;
    state.activeProject = { ...state.activeProject, ...project };
  },
  resetProject(state) {
    state.activeProject = defaultProject();
  },
  setProjectPrice(state, price) {
    state.projectPrice = price;
  },
  setProjectsLoading(state, status) {
    state.projectsLoading = status;
  },
  setCoordinates(state, value) {
    state.coordinates = value;
  },
  setProjectTypeId(state, projectTypeId) {
    state.activeProject.projectTypeId = Number(projectTypeId);
  },
  setTemplates(state, templates) {
    state.templates = templates;
  },
  updatePathStackLength(state, length) {
    state.pathStackLength = length;
  },
};

const actions = {
  resetState: (context) => {
    context.commit("resetState");
  },
  updateActiveProject: async function (context, formData) {
    context.commit("setProjectsLoading", true);

    try {
      let project = await ProjectRepository.putAsync(formData);
      project.data.price = formData.price;

      context.commit("setProject", project.data);
    } catch (error) {
      throw error;
    } finally {
      context.commit("setProjectsLoading", false);
    }
  },
  setActiveProjectById: async function (context, projectId) {
    let project = await ProjectRepository.getByIdAsync(projectId);
    context.commit("setProject", project.data);
  },
  async recalculateProject(context) {
    context.commit("setProjectsLoading", true);
    let result = await ProjectRepository.calculateCost(context.state.activeProject.id);

    if (result.status !== 200 && result.status !== 201) {
      console.error("Could not calculate Project. Message: ", result);
      return;
    }

    let projectData = result.data;

    context.commit("setProjectPrice", projectData.price);
    context.commit("setProjectsLoading", false);
  },
  fetchTemplates({ commit }) {
    smartNoteTemplateAPI
      .get()
      .then((response) => {
        commit("setTemplates", response.data.items);
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
