<!--Usage: should render svg icons which are importet as .vue files!
gives us the ability to also animate them!-->
<!-- to generate valid svg files use
svg-sprite-generate -l D:\smartbricks\Configurator\static\icons\logo.svg -o D:\smartbricks\Configurator\static\icons\logoSprite.svg-->
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="width"
    :height="height"
    :viewBox="viewBox"
    :aria-labelledby="iconName"
    :role="role"
  >
    <!--    <title :id="iconName" lang="en">{{ iconName }} icon</title>-->
    <g :fill="iconColor">
      <!--      <slot />-->
      <component :is="getIcon().file" />
    </g>
  </svg>
</template>

<script>
export default {
  name: "IconBase",
  props: {
    iconName: {
      type: String,
      default: "dach",
    },
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "black",
    },
    role: {
      type: String,
      default: "graphics-symbol",
    },
    viewBox: {
      type: String,
      default: "0 0 32 32",
    },
  },
  data: () => ({
    icons: [
      {
        name: "addon",
        component: "iconRoofExtension",
        file: () => import(`../Icons/iconRoofExtension`),
      },
      {
        name: "abzugsf",
        component: "iconAbzugsf",
        file: () => import(`../Icons/iconAbzugsf`),
      },
      {
        name: "dach",
        component: "iconRoof",
        file: () => import("../Icons/iconRoof"),
      },
      {
        name: "wand",
        component: "iconWall",
        file: () => import(`../Icons/iconWall`),
      },
      {
        name: "decke",
        component: "iconCelling",
        file: () => import(`../Icons/iconCelling`),
      },
      {
        name: "terasse",
        component: "iconTerrace",
        file: () => import(`../Icons/iconTerrace`),
      },
      {
        name: "boden",
        component: "iconFloor",
        file: () => import(`../Icons/iconFloor`),
      },
      {
        name: "logo",
        component: "iconLogoTest",
        file: () => import(`@/components/core/Icons/iconLogo`),
      },
      {
        name: "startConfig",
        component: "iconStartConfig",
        file: () => import(`@/components/core/Icons/iconStartConfig.vue`),
      },
      {
        name: "elektro",
        component: "iconElektro",
        file: () => import(`@/components/core/Icons/iconElektro.vue`),
      },
      {
        name: "measure",
        component: "iconMeasure",
        file: () => import(`@/components/core/Icons/iconMeasure.vue`),
      },
      {
        name: "share",
        component: "iconShare",
        file: () => import(`@/components/core/Icons/iconShare.vue`),
      },
      {
        name: "roofExtension",
        component: "iconRoofExtension",
        file: () => import(`@/components/core/Icons/iconRoofExtension.vue`),
      },
      {
        name: "flatRoof",
        component: "iconFlatRoof",
        file: () => import(`@/components/core/Icons/iconFlatRoof.vue`),
      },
      {
        name: "flachdach",
        component: "iconFlatRoof",
        file: () => import(`@/components/core/Icons/iconFlatRoof.vue`),
      },
      {
        name: "reference",
        component: "iconReference",
        file: () => import(`@/components/core/Icons/iconReference.vue`),
      },
      {
        name: "move",
        component: "iconMove",
        file: () => import(`@/components/core/Icons/iconMove.vue`),
      },
      {
        name: "bestanddeckung",
        component: "iconBestanddeckung.vue",
        file: () => import(`@/components/core/Icons/iconBestanddeckung.vue`),
      },
      {
        name: "pipe",
        component: "iconPipe.vue",
        file: () => import(`@/components/core/Icons/iconPipe.vue`),
      },
      {
        name: "bestandFlachdach",
        component: "iconBestandFlachdach.vue",
        file: () => import(`@/components/core/Icons/iconBestandFlachdach.vue`),
      },
      {
        name: "group",
        component: "iconGroup.vue",
        file: () => import(`@/components/core/Icons/iconGroup.vue`),
      },
      {
        name: "wallGroup",
        component: "iconWallGroup.vue",
        file: () => import(`@/components/core/Icons/iconWallGroup.vue`),
      },
      {
        name: "repairRoof",
        component: "IconRepairRoof.vue",
        file: () => import(`@/components/core/Icons/iconRepairRoof.vue`),
      },
      {
        name: "iconRepairRoofFlat",
        component: "iconRepairRoofFlat.vue",
        file: () => import(`@/components/core/Icons/iconRepairRoofFlat.vue`),
      },
      {
        name: "iconExportCsv",
        component: "IconExportCsv.vue",
        file: () => import(`@/components/core/Icons/iconExportCsv.vue`),
      },
      {
        name: "iconExportPdf",
        component: "IconExportPdf.vue",
        file: () => import(`@/components/core/Icons/iconExportPdf.vue`),
      },
      {
        name: "iconExportOnlv",
        component: "IconExportOnlv.vue",
        file: () => import(`@/components/core/Icons/iconExportOnlv.vue`),
      },
      {
        name: "iconExistance",
        component: "iconExistance.vue",
        file: () => import(`@/components/core/Icons/iconExistance.vue`),
      },
      {
        name: "iconResetToZero",
        component: "iconResetToZero.vue",
        file: () => import(`@/components/core/Icons/iconResetToZero.vue`),
      },
      {
        name: "iconRotateLeftOne",
        component: "iconRotateLeftOne.vue",
        file: () => import(`@/components/core/Icons/iconRotateLeftOne.vue`),
      },
      {
        name: "iconRotateLeftFive",
        component: "iconRotateLeftFive.vue",
        file: () => import(`@/components/core/Icons/iconRotateLeftFive.vue`),
      },
      {
        name: "iconRotateLeftNinety",
        component: "iconRotateLeftNinety.vue",
        file: () => import(`@/components/core/Icons/iconRotateLeftNinety.vue`),
      },
      {
        name: "iconRotateRightOne",
        component: "iconRotateRightOne.vue",
        file: () => import(`@/components/core/Icons/iconRotateRightOne.vue`),
      },
      {
        name: "iconRotateRightFive",
        component: "iconRotateRightFive.vue",
        file: () => import(`@/components/core/Icons/iconRotateRightFive.vue`),
      },
      {
        name: "iconRotateRightNinety",
        component: "iconRotateRightNinety.vue",
        file: () => import(`@/components/core/Icons/iconRotateRightNinety.vue`),
      },
      {
        name: "iconRotateRightFifteen",
        component: "iconRotateRightFifteen.vue",
        file: () => import(`@/components/core/Icons/iconRotateRightFifteen.vue`),
      },
      {
        name: "iconRotateLeftFifteen",
        component: "iconRotateLeftFifteen.vue",
        file: () => import(`@/components/core/Icons/iconRotateLeftFifteen.vue`),
      },
      {
        name: "iconFolder",
        component: "iconFolder.vue",
        file: () => import(`@/components/core/Icons/cloud/iconFolder.vue`),
      },
      {
        name: "heatingGroup",
        component: "iconHeiztechnikGruppe.vue",
        file: () => import(`@/components/core/Icons/iconHeiztechnikGruppe.vue`),
      },
      {
        name: "lineAccessories",
        component: "iconLeitungszubehor.vue",
        file: () => import(`@/components/core/Icons/iconLeitungszubehor.vue`),
      },
      {
        name: "heating",
        component: "iconHeiztechnik.vue",
        file: () => import(`@/components/core/Icons/iconHeiztechnik.vue`),
      },
      {
        name: "pipeHK",
        component: "iconPipeHK.vue",
        file: () => import(`@/components/core/Icons/iconPipeHK.vue`),
      },
      {
        name: "PipeSanitär",
        component: "iconPipeSanitär.vue",
        file: () => import(`@/components/core/Icons/iconPipeSanitär.vue`),
      },
      {
        name: "Sanitär",
        component: "iconSanitär.vue",
        file: () => import(`@/components/core/Icons/iconSanitär.vue`),
      },
      {
        name: "HeizKühltechnik",
        component: "iconHeizKühltechnik.vue",
        file: () => import(`@/components/core/Icons/iconHeizKühltechnik.vue`),
      },
      {
        name: "pipeAir",
        component: "iconPipeAir.vue",
        file: () => import(`@/components/core/Icons/iconPipeAir.vue`),
      },
      {
        name: "pipeZubehör",
        component: "iconPipeZubehör.vue",
        file: () => import(`@/components/core/Icons/iconPipeZubehör.vue`),
      },
      {
        name: "map-marker",
        component: "iconMap-marker.vue",
        file: () => import(`@/components/core/Icons/iconMap-marker.vue`),
      },
    ],
  }),
  methods: {
    getIcon() {
      let icon = this.icons.find((icon) => icon.name.toLowerCase().localeCompare(this.iconName.toLowerCase()) === 0);
      if (icon == null) return this.icons[0];
      return icon;
    },
  },
};
</script>

<style scoped>
svg {
  display: inline-block;
  vertical-align: baseline;
  margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
</style>
