/*export default function({ app }) {
  if (window && (window.Cypress || window.cy)) {
    window.app = app;
  }
}*/
export default ({ store }) => {
  // if (true) {
  window.store = store;
  // }
};
