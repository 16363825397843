// initial state
import CompanyRepository from "@/Repository/Company.api";
import UserRepository from "@/Repository/User.api";
import CustomerRepository from "@/Repository/Customer.api";
import ProjectRepository from "@/Repository/Project.api";
import _ from "lodash";

const getDefaultState = () => {
  return {
    company: {
      id: 0,
      name: "",
      communication: {
        id: 0,
      },
      organisationStructure: {
        id: 0,
      },
      showPrices: false,
    },
    user: {
      id: 0,
      name: "",
      contact: {
        email: "",
      },
    },
    customers: [],
    projects: [],
    showPrices: true,
    loading: false,
  };
};
// initial state
const state = getDefaultState;
const getters = {
  getCompanyName: (state) => state.company.name,
};

const mutations = {
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setCompany(state, company) {
    state.company = company;
  },
  setUser(state, user) {
    state.user = user;
  },
  setCustomers(state, customers) {
    state.customers = customers;
  },
  addCustomer(state, customer) {
    state.customers.push(customer);
  },
  updateCustomer(state, { customer, index }) {
    state.customers.splice(index, 1, customer);
  },
  deleteCustomer(state, index) {
    state.customers.splice(index, 1);
  },
  addProjects(state, projects) {
    state.projects = projects;
  },
  addProject(state, project) {
    state.projects.push(project);
  },
  updateProject(state, { index, project }) {
    state.projects.splice(index, 1, project);
  },
  setLoading(state, loading) {
    state.loading = loading;
  },
};

const actions = {
  resetState: (context) => {
    context.commit("resetState");
  },
  loadCompany: async function (context) {
    const user = await UserRepository.getActive();
    context.commit("setUser", user.data);

    const result = await CompanyRepository.getCompanyByEmail(user.data.contact.communication.email);

    context.commit("setCompany", result.data);
  },
  loadProjects: async function (context) {
    const result = await ProjectRepository.get().catch((error) => {
      throw new Error("projects cannot be loaded!");
    });

    if (result && result.data) {
      context.commit("addProjects", result.data);
    }
  },
  loadCustomers: async function (context) {
    const result = await CustomerRepository.getCreateProject();

    context.commit("setCustomers", result.data);
  },
  updateCompany: async function (context, company) {
    context.commit("setLoading", true);

    let result = await CompanyRepository.putDashboard(company);
    context.commit("setCompany", result.data);

    context.commit("setLoading", false);
  },
  addCustomer: async function (context, customer) {
    context.commit("setLoading", true);

    let result = await CustomerRepository.post(customer);

    context.commit("addCustomer", result.data);
    context.commit("setLoading", false);
    return result.data;
  },
  updateCustomer: async function (context, customer) {
    context.commit("setLoading", true);

    let result = await CustomerRepository.put(customer);
    let index = context.state.customers.findIndex((cust) => cust.id === customer.id);

    if (index === -1) {
      throw "Customer not found";
    }

    context.commit("updateCustomer", { customer: result.data, index: index });
    context.commit("setLoading", false);
    return result.data;
  },
  deleteCustomer: async function (context, id) {
    context.commit("setLoading", true);

    await CustomerRepository.delete(id);
    let index = context.state.customers.findIndex((cust) => cust.id === id);

    if (index === -1) {
      throw "Customer not found";
    }

    context.commit("deleteCustomer", index);
    context.commit("setLoading", false);
  },
  updateProject(context, { id, status }) {
    context.commit("setLoading", true);
    let index = context.state.projects.findIndex((proj) => proj.id === id);

    if (index === -1) {
      console.error("Project not found");
      return;
    }

    let project = _.cloneDeep(context.state.projects[index]);
    project.status = status;

    return ProjectRepository.updateStatus(project).then((res) => {
      context.commit("updateProject", { index: index, project: res.data });
      context.commit("setLoading", false);
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
