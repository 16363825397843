/*
    blueprint.js

    Holds information about the available productGroups
 */

import SmartBuildTypeRepository from "@/Repository/SmartBuildType.api";
import DrawTypeRepository from "@/Repository/DrawType.api";
import SmartBuildApi from "@/Repository/SmartBuild.api";

const getDefaultState = () => {
  return {
    smartBuildTypes: [],
    smartBuilds: [],
    drawTypes: [],
    gridOptions: {
      maxValue: 100,
      minValue: 0,
      value: 80,
    },
    showUploadDialog: false,
    changeImage: false,
    showDebugInfo: false,
    blueprintLoading: false,
    roofIdInDetailMode: "",
  };
};

export const state = getDefaultState;

const getters = {
  getSmartBuildById: (state) => (id) => state.smartBuilds.find((pg) => pg.id === id),

  getRoofIDInDetailMode: (state) => state.roofIdInDetailMode,
};

const mutations = {
  setSmartBuildTypes(state, smartBuildTypes) {
    state.smartBuildTypes = smartBuildTypes;
  },
  setSmartBuilds(state, smartBuilds) {
    state.smartBuilds = smartBuilds;
  },
  setDrawTypes(state, drawTypes) {
    state.drawTypes = drawTypes;
  },
  setGridValue(state, value) {
    state.gridOptions.value = value;
  },
  setShowUploadDialog(state, value) {
    state.showUploadDialog = value;
  },
  setChangeImage(state, value) {
    state.changeImage = value;
    state.showUploadDialog = value;
  },
  setShowDebugInfo(state, value) {
    state.showDebugInfo = value;
  },
  setRoofIdInDetailMode(state, value) {
    state.roofIdInDetailMode = value;
  },
};

const actions = {
  loadSmartBuildTypes: async function (context) {
    const result = await SmartBuildTypeRepository.getSmartBuildBlueprint();
    context.commit("setSmartBuildTypes", result.data);
  },
  loadSmartBuilds: async function (context) {
    const result = await SmartBuildApi.getSmartBuildBlueprint();
    context.commit("setSmartBuilds", result.data);
  },
  loadDrawTypes: async function (context) {
    const result = await DrawTypeRepository.getBlueprint();
    context.commit("setDrawTypes", result.data);
  },
  changeRoofIDInDetailMode: async function (context, blueprintId) {
    context.commit("setRoofIdInDetailMode", blueprintId);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
