<template>
  <v-app>
    <main-sidebar :pages="[]" link-back-to-main />
    <v-main :style="paddingLeft">
      <nuxt />
    </v-main>
  </v-app>
</template>

<script>
import mainSidebar from "@/components/core/bars/mainSidebar";
import auth from "../mixins/authGuard";
import appModeMixin from "@/mixins/appModeMixin";

export default {
  name: "Sidebar",
  components: {
    mainSidebar,
  },
  mixins: [auth, appModeMixin],
  data() {
    return {
      isMobileView: false,
    };
  },
  computed: {
    paddingLeft() {
      return `padding-left: ${this.isMobileView ? "0px" : "75px"} !important`;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  methods: {
    handleResize() {
      this.isMobileView = window.innerWidth < 450;
    },
  },
};
</script>

<style scoped></style>
