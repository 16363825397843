import Vue from "vue";

Vue.filter("getAttributeValueByName", function (attributes, attributeName) {
  if (!attributes) {
    return null;
  }
  for (let atb of attributes) {
    if (atb.attribute.name.localeCompare(attributeName) === 0) {
      if (atb.attribute.unit.localeCompare("cm") === 0) {
        return atb.value / 100 + " m";
      }
      return atb.value + " " + atb.attribute.unit;
    }
  }
  return null;
});

Vue.filter("getAttributeUnit", function (attribute) {
  if (!attribute) {
    return "";
  }
  switch (attribute.name.toLowerCase()) {
    case "länge":
      return "cm";
    case "breite":
      return "cm";
    case "höhe":
      return "cm";
    case "fläche":
      return "m²";
  }
  return null;
});
