/* eslint-disable */

// initial state
const state = {
  activePlannerObject: null,
  grid: null
};

const getters = {
  activePlannerObject: state => state.activePlannerObject,
  grid: state => state.grid
};

const mutations = {

};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
