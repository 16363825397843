<template>
  <v-app>
    <v-app-bar
      app
      elevate-on-scroll
      :color="showBaseUrlSelection && devSignedIn ? getServerBaseUrl.color : 'white'"
      style="border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important"
    >
      <v-btn v-show="safeBreakpoint.mdAndUp" icon class="px-5" nuxt to="/">
        <v-icon color="#000000">mdi-home-outline</v-icon>
      </v-btn>
      <v-divider v-show="safeBreakpoint.mdAndUp" class="mx-1" vertical />
      <span v-show="safeBreakpoint.mdAndUp" class="title ml-1">Projektübersicht</span>
      <v-spacer v-show="safeBreakpoint.mdAndUp" />
      <v-toolbar-title>
        <span class="smartbricks">SMARTBRICKS</span>
        <icon-base width="25px" class="ml-1" height="25px" icon-name="logo" icon-color="black" />
      </v-toolbar-title>

      <base-url-selector v-model="showBaseUrlSelection" />
      <v-spacer />

      <!-- <template v-if="version != null">
        <v-btn v-if="safeBreakpoint.smAndUp" text color="primary" @click="showNewFeaturesDialog = true">
          <v-icon class="mr-1"> mdi-gift </v-icon>Smartbricks {{ version.number }}
        </v-btn>
        <version-dialog v-model="showNewFeaturesDialog" :version="version" />
      </template> -->

      <!-- v-if="safeBreakpoint.mdAndUp" -->
      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <div v-show="safeBreakpoint.mdAndUp">
            <span class="font-weight-medium" v-bind="attrs" v-on="on">{{ company.name }}</span>
            <v-icon color="black" v-bind="attrs" v-on="on"> mdi-account-hard-hat </v-icon>
            <v-icon color="black" v-bind="attrs" v-on="on">mdi-help</v-icon>
          </div>
          <div v-show="safeBreakpoint.smAndDown">
            <v-icon color="black" v-bind="attrs" v-on="on">mdi-menu</v-icon>
          </div>
        </template>
        <v-list class="py-0">
          <v-list-item v-for="(page, index) in pages" :key="index" class="pa-3" @click="navigateToPage(page.link)">
            <v-list-item-icon>
              <v-icon large class="mr-10" color="black">{{ page.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="font-size: 20px" color="black">{{ page.title }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            v-if="showSmartcademyLink"
            class="pa-3"
            href="https://www.smartbricks.at/smartcademy"
            target="_blank"
          >
            <v-list-item-icon>
              <v-icon class="mr-10" color="black" large>mdi-school-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="font-size: 20px" color="black">Smartcademy</v-list-item-title>
          </v-list-item>
          <v-list-item class="pa-3" @click="logout">
            <v-list-item-icon>
              <v-icon large class="mr-10" color="red">mdi-logout-variant</v-icon>
            </v-list-item-icon>
            <v-list-item-title style="font-size: 20px; color: red">Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-bottom-navigation
      v-if="safeBreakpoint.smAndDown && !isHomepage"
      :value="safeFullPath"
      height="75"
      grow
      app
      color="primary"
    >
      <v-btn
        v-for="(page, index) in pages.filter((p) => !p.desktopOnly)"
        :key="index"
        v-ripple="false"
        :value="page.link"
        nuxt
        link
        :to="page.link"
      >
        <span>{{ page.title }}</span>
        <v-icon>
          {{ page.icon }}
        </v-icon>
      </v-btn>
    </v-bottom-navigation>
    <!--Workaround to prevent navigation-drawer from hiding, see https://github.com/vuetifyjs/vuetify/issues/5617 -->
    <v-main>
      <nuxt />
    </v-main>
  </v-app>
</template>

<script>
import iconBase from "@/components/core/Icons/iconBase";
import authInfo from "@/mixins/authFunctions";
import { mapActions, mapGetters, mapState } from "vuex";
import baseUrlSelector from "@/components/core/internal/baseUrlSelector";
import $ from "jquery";
import auth from "@/mixins/authGuard";
import appModeMixin from "@/mixins/appModeMixin";
import VersionApi from "@/Repository/Version.api";
// import VersionDialog from "@/components/custom/smartbrickDashboard/versionDialog.vue";
import checkUserRights from "@/mixins/checkUserRights";
import breakpointsMixin from "@/mixins/breakpointsMixin";
export default {
  name: "Dashboard",
  components: {
    baseUrlSelector,
    // VersionDialog,
    iconBase,
  },
  mixins: [auth, authInfo, appModeMixin, checkUserRights, breakpointsMixin],

  data: () => ({
    activePage: 0,
    pages: [
      {
        title: "Projekte",
        icon: "mdi-account-hard-hat",
        link: "/",
      },
      {
        title: "Kunden",
        icon: "mdi-card-account-details-outline",
        link: "/customerOverview",
      },
      {
        title: "Einstellungen",
        icon: "mdi-account-cog",
        link: "/companySettings",
      },
      {
        title: "Positionen",
        icon: "mdi-alpha-z-circle",
        link: "/positions",
      },
    ],
    showBaseUrlSelection: false,
    load: true,
    showNewFeaturesDialog: false,
    version: null,
  }),
  // add the msft clarity script
  head() {
    return {
      script: [{}],
    };
  },
  computed: {
    ...mapGetters("general", ["getServerBaseUrl"]),
    ...mapGetters("project", {
      activeProject: "getActiveProject",
    }),
    ...mapState("company", {
      company: (state) => state.company,
    }),
    showSmartcademyLink() {
      if (!process.client) return false;
      return this.$route.path !== "/project" && this.$route.path !== "/quantitySurveyProject";
    },
    // Computed property to shorten username if not enough space
    // companyName() {
    //   return this.isMobileView ? this.company.name.substring(0, 5) : this.company.name;
    // },
    safeFullPath() {
      return process.client ? this.$route.fullPath : "";
    },
    isHomepage() {
      return this.$route.path === "/";
    },
  },
  mounted() {
    this.load = true;
    this.loadCompany()
      .then(() => (this.load = false))
      .finally(() => {
        if (this.company.isSeller) {
          let leistungsbuch = {
            title: "Leistungsbuch",
            icon: "mdi-calculator-variant-outline",
            link: "/leistungsbuch",
            desktopOnly: true,
          };

          let artikelverwaltung = {
            title: "Artikelverwaltung",
            icon: "mdi-package-variant-closed",
            link: "/articles",
            desktopOnly: true,
          };

          this.pages.push(leistungsbuch);
          this.pages.push(artikelverwaltung);
        }
      });

    VersionApi.get()
      .then((result) => {
        this.version = result.data;
      })
      .catch((error) => {
        console.error("Could not load Version.");
      });

    // add experimental features here
    // since the login is not done yet, we need to wait a bit to check the user rights
    setTimeout(() => {
      // check the user rights and add template designer if is Admin
      if (this.IsDeveloper() && this.safeBreakpoint.smAndUp) {
        let templateDesigner = {
          title: "SmartNote Template Designer",
          icon: "mdi-form-select",
          link: "/templateDesigner",
        };
        this.pages.push(templateDesigner);
      }
    }, 2000);

    $("body").attr("style", "");
  },
  methods: {
    ...mapActions("company", ["loadCompany"]),
    navigateToPage(link) {
      if (!process.client) return false;
      console.log("link:", link);
      this.$router.push(link);
    },
  },
};
</script>

<style scoped>
html {
  overflow: hidden;
  overscroll-behavior: none;
}
.smartbricks {
  /*   font-size: ;
 */
  font-weight: bold;
}
</style>
