export default {
  data() {
    return {
      isClient: false,
    };
  },
  computed: {
    safeBreakpoint() {
      // If it's not client-side, return a default breakpoint object
      if (!this.isClient) {
        let mockBreakpoint = {
          xs: false,
          sm: false,
          md: false,
          lg: false,
          xl: false,
          smAndDown: false,
          smAndUp: true,
          mdAndDown: false,
          mdAndUp: true,
          lgAndDown: false,
          lgAndUp: true,
          width: 0,
        };

        return mockBreakpoint;
      }

      // If client-side, return the actual breakpoint from Vuetify
      return this.$vuetify.breakpoint;
    },
  },
  mounted() {
    this.isClient = true;
  },
};
