/*
    smartNotes.js

    
    Holds information about the available smartNotes
 */

import smartNoteGroupAPI from "@/Repository/SmartNoteGroup.api.js";

const getDefaultState = () => {
  return {
    showBackButton: false,
    showSmartNote: false,
    clickedComponent: null,
    selectedComponent: null,
    selectedComponentIndex: null,
    isAnyNoteSelected: false,
    activeSmartNote: {},
    activeSmartNoteGroup: {},
    groupDateAndName: "Date Created | Note Name",
    isTyping: false,
    showSearchBar: false,
    isExpanded: false,
    selectedImage: null,
    isAnyTemplateSelected: false,
    drawingEnabled: false,
    groups: [],
    checkedGroups: [],
    addingGroup: false,
    isConfiguration: false,
    showFullScreenNoteBuilder: false,
    dropdownIsVisible: false,
  };
};

export const state = getDefaultState;

const getters = {
  getActiveSmartNote: (state) => {
    return state.activeSmartNote;
  },
  getActiveSmartNoteGroup: (state) => {
    return state.activeSmartNoteGroup;
  },
  getGroupDateAndName: (state) => {
    if (state.activeSmartNoteGroup) {
      const date = new Date(state.activeSmartNoteGroup.createdDate);
      const name = state.activeSmartNoteGroup.name;
      const formattedDate = `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()}`;
      return `${formattedDate} | ${name}`;
    } else {
      return "Date Created | Note Name";
    }
  },
  getClickedComponent: (state) => {
    return state.clickedComponent;
  },
  getIsTyping: (state) => {
    return state.isTyping;
  },
  getShowSearchBar: (state) => {
    return state.showSearchBar;
  },
  getIsExpanded: (state) => {
    return state.isExpanded;
  },
  getSelectedImage: (state) => {
    return state.selectedImage;
  },
  getIsAnyTemplateSelected: (state) => {
    return state.isAnyTemplateSelected;
  },
  getDrawingEnabled: (state) => {
    return state.drawingEnabled;
  },
  getAddingGroup: (state) => {
    return state.addingGroups;
  },
  getIsConfiguration: (state) => {
    return state.isConfiguration;
  },
  getShowFullScreenNoteBuilder: (state) => {
    return state.showFullScreenNoteBuilder;
  },
  getDropdownIsVisible: (state) => {
    return state.dropdownIsVisible;
  },
};

const mutations = {
  setShowBackButton(state, value) {
    state.showBackButton = value;
  },
  setShowSmartNote(state, value) {
    state.showSmartNote = value;
  },
  setClickedComponent(state, data) {
    state.clickedComponent = data;
  },
  setAnyNoteSelected(state, value) {
    state.anyNoteSelected = value;
  },
  setActiveSmartNote(state, note) {
    state.activeSmartNote = note;
  },
  setActiveSmartNoteGroup(state, group) {
    state.activeSmartNoteGroup = group;
  },
  setGroupDateAndName(state, value) {
    state.groupDateAndName = value;
  },
  setSelectedComponent(state, component) {
    state.selectedComponent = component;
  },
  setSelectedComponentIndex(state, index) {
    state.selectedComponentIndex = index;
  },
  setIsTyping(state, value) {
    state.isTyping = value;
  },
  setShowSearchBar(state, value) {
    state.showSearchBar = value;
  },
  setIsExpanded(state, value) {
    state.isExpanded = value;
  },
  setSelectedImage(state, value) {
    state.selectedImage = value;
  },
  setIsAnyTemplateSelected(state, value) {
    state.isAnyTemplateSelected = value;
  },
  setDrawingEnabled(state, value) {
    state.drawingEnabled = value;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setGroups(state, groups) {
    state.groups = groups;
  },
  updateGroup(state, { groupIndex, isChecked }) {
    const group = state.groups[groupIndex];
    state.groups[groupIndex] = { ...group, isChecked };
  },
  addCheckedGroup(state, group) {
    state.checkedGroups.push(group);
  },
  removeCheckedGroup(state, index) {
    state.checkedGroups.splice(index, 1);
  },
  setAddingGroup(state, value) {
    state.addingGroup = value;
  },
  setIsConfiguration(state, value) {
    state.isConfiguration = value;
  },
  setShowFullScreenNoteBuilder(state, value) {
    state.showFullScreenNoteBuilder = value;
  },
  setDropdownIsVisible(state, value) {
    state.dropdownIsVisible = value;
  },
};

const actions = {
  updateShowBackButton({ commit }, value) {
    commit("setShowBackButton", value);
  },
  updateShowSmartNote({ commit }, value) {
    commit("setShowSmartNote", value);
  },
  updateClickedComponent({ commit }, data) {
    commit("setClickedComponent", data);
  },
  updateSetAnyNoteSelected({ commit }, value) {
    commit("setAnyNoteSelected", value);
  },
  updateActiveSmartNote({ commit }, note) {
    commit("setActiveSmartNote", note);
  },
  updateActiveSmartNoteGroup({ commit }, group) {
    commit("setActiveSmartNoteGroup", group);
  },
  async deleteSelectedGroups({ commit }, groups) {
    try {
      let selectedGroupIds = groups.filter((group) => group.isChecked).map((group) => group.id);
      console.log(selectedGroupIds, "SELECTED GROUP");
      for (const id of selectedGroupIds) {
        await smartNoteGroupAPI.delete(id);
      }
    } catch (error) {
      console.error("An error occurred while deleting selected groups:", error);
    }
  },
  updateGroupDateAndName({ commit }, value) {
    commit("setGroupDateAndName", value);
  },
  updateSelectedComponent({ commit }, value) {
    commit("setSelectedComponent", value);
  },
  updateSelectedComponentIndex({ commit }, index) {
    commit("setSelectedComponentIndex", index);
  },
  updateIsTyping({ commit }, value) {
    commit("setIsTyping", value);
  },
  updateShowSearchBar({ commit }, value) {
    commit("setShowSearchBar", value);
  },
  updateIsExpanded({ commit }, value) {
    commit("setIsExpanded", value);
  },
  updateSelectedImage({ commit }, value) {
    commit("setSelectedImage", value);
  },
  updateIsAnyTemplateSelected({ commit }, value) {
    commit("setIsAnyTemplateSelected", value);
  },
  updateDrawingEnabled({ commit }, value) {
    commit("setDrawingEnabled", value);
  },
  resetStoreState({ commit }) {
    commit("resetState");
  },
  async fetchGroups(context, { selectedTemplate, activeProject }) {
    try {
      let templateId = selectedTemplate.id;
      const response = await smartNoteGroupAPI.get(templateId, activeProject.id);
      context.commit(
        "setGroups",
        response.data.items.map((group) => ({ ...group, isChecked: false }))
      );
    } catch (error) {
      console.error(error);
    }
  },
  updateAddingGroup({ commit }, value) {
    commit("setAddingGroup", value);
  },
  updateIsConfiguration({ commit }, value) {
    commit("setIsConfiguration", value);
  },
  updateShowFullScreenNoteBuilder({ commit }, value) {
    commit("setShowFullScreenNoteBuilder", value);
  },
  updateDropdownIsVisible({ commit }, value) {
    commit("setDropdownIsVisible", value);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
