import { Loader } from "google-maps";
import Vue from "vue";

export default async ({ $config: { googleMapsApiKey } }, inject) => {
  const options = {
    libraries: ["places"],
  };
  const loader = new Loader(googleMapsApiKey, options);
  const googleMaps = await loader.load();
  inject("googleMaps", googleMaps);
};
