import auth0 from "@/auth/auth0";
import authFunctions from "@/mixins/authFunctions";
export default {
  data() {
    return {};
  },
  mixins: [authFunctions],
  async mounted() {
    console.log("auth guard mounted");
    if (localStorage.authError) {
      console.log("auth error!");
      return;
    }
    let user = null;
    try {
      await auth0.getTokenSilently();
      user = await auth0.getUser();
      if (!user.email_verified) {
        // eslint-disable-next-line no-debugger
        // localStorage.setItem("errorCode", 1);
        this.$router.push("error?errorcode=1");
        // this.logout("emailNotVerified", true);
      }
      console.log("User : ", user);
    } catch (e) {
      let redir = window.location.protocol + "//" + location.host + "/empty";
      console.log("redir after auth to: ", redir);
      let redirUri = window.location.pathname + window.location.search;
      localStorage.setItem("redirURL", redirUri);
      auth0.loginWithRedirect({ redirect_uri: redir });
    }

    try {
      console.log("identifying user");
      if (user != null) {
        this.$logRocket.identify(user.name, {
          name: user.name,
          email: user.email,
        });
        // identify user in pendo
        window.pendo.initialize({
          visitor: {
            id: user.email,
            email: user.email,
          },
          account: {
            id: user.email,
            roles: user["https://auth.smartbricks.at/roles"],
          },
        });
      }
    } catch (e) {
      console.warn("cannot identify user to log rocket!", e);
    }
  },
  methods: {},
};
